export default class ImageContainerViewModel {
  public static createEmpty(): ImageContainerViewModel {
    return new ImageContainerViewModel();
  }
  constructor(
    public ImageHeading: string = '',
    public ShowImageHeading: boolean = false,
    public ImageDescription: string = '',
    public ShowImageDescription: boolean = false,
    public DescriptionMargin: string = '',
    public ImageUrl: string = '',
    public ImageBackground: string = '',
    public TooltipText: string = '',
    public ShowTooltip: boolean = false,
    public IsLogoMandatory: boolean = false,
    public ValidateLogo: boolean = false,
  ) {}
}

