
import ImageContainerViewModel from '@/entities/ImageContainerViewModel';
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
@Component({
})
class ImageContainer extends Vue {
  @Prop() imageContainerData!: ImageContainerViewModel;
  private localImageContainerData: ImageContainerViewModel = ImageContainerViewModel.createEmpty();
  private addIcon = require('@/assets/Images/add-icon-grey.svg');
  private showImageInformationString = false;

  // Mounted life cycle hook
  private mounted() {
    this.localImageContainerData = Object.assign({}, this.imageContainerData);
    // Remove red border once image url is not empty
    if(this.localImageContainerData.ImageUrl !== '') {
      this.localImageContainerData.ValidateLogo = false;
    }
  }

  // Show hide image information tooltip
  private ShowHideImageInformationString(val: boolean) {
    this.showImageInformationString = val;
  }

  // Used to set the image url
  private GetImageUrl() {
    return this.localImageContainerData.ImageUrl !== null && this.localImageContainerData.ImageUrl !== undefined && this.localImageContainerData.ImageUrl !== '' ? this.localImageContainerData.ImageUrl : this.addIcon;
  }

  // Check if image icon is clicked such that image process component can be shown
  private ImageContainerClicked() {
    this.$emit('image-container-clicked');
  }

  // Watch to check change in imageContainerData
  @Watch('imageContainerData', {deep: true})
  private CheckChangeInImageContainerData(val: ImageContainerViewModel) {
    this.localImageContainerData = val;
  }
}
export default toNative(ImageContainer);
