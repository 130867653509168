import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23b7ce19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-image-container" }
const _hoisted_2 = {
  key: 0,
  class: "color-red"
}
const _hoisted_3 = {
  class: "image-label cursor-pointer",
  for: "img"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "display-flex align-items-center" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({'display-flex': _ctx.localImageContainerData.ImageHeading === ''})
    }, [
      _createElementVNode("label", null, [
        _createTextVNode(_toDisplayString(_ctx.localImageContainerData.ImageHeading) + " ", 1),
        (_ctx.localImageContainerData.IsLogoMandatory)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("Asteric")), 1))
          : _createCommentVNode("", true),
        (_ctx.localImageContainerData.ShowTooltip)
          ? (_openBlock(), _createElementBlock("i", {
              key: 1,
              class: "fa fa-info-circle margin-left-5",
              "aria-hidden": "true",
              onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ShowHideImageInformationString(true))),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ShowHideImageInformationString(false)))
            }, null, 32))
          : _createCommentVNode("", true)
      ]),
      _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.localImageContainerData.TooltipText), 513), [
        [_vShow, _ctx.showImageInformationString]
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["image-div cursor-pointer margin-bottom-10 border-radius-10", [_ctx.localImageContainerData.ImageUrl !=='' && _ctx.localImageContainerData.ImageUrl !== null ? 'solid-border': 'dashed-border', {'border-red': _ctx.localImageContainerData.ValidateLogo}]]),
        style: _normalizeStyle({'background': _ctx.localImageContainerData.ImageUrl !=='' && _ctx.localImageContainerData.ImageUrl !== null ? _ctx.localImageContainerData.ImageBackground : ''}),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ImageContainerClicked()))
      }, [
        _createElementVNode("label", _hoisted_3, [
          _createElementVNode("img", {
            class: "image-fit",
            src: _ctx.GetImageUrl()
          }, null, 8, _hoisted_4)
        ])
      ], 6)
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.localImageContainerData.ShowImageDescription)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            style: _normalizeStyle({'margin': _ctx.localImageContainerData.DescriptionMargin}),
            class: "image-size-warning small-description",
            innerHTML: _ctx.localImageContainerData.ImageDescription
          }, null, 12, _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ]))
}